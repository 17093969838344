import ProjectPreviewGrid from "../components/project-preview-grid";
import IllustrationGrid from "../components/illustration-grid";
import Container from "../components/container";
import WorkFilters from "../components/work-filters";
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import React from "react";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import { mapEdgesToNodes } from "../lib/helpers";

import { useQueryParam } from "gatsby-query-params";

export const query = graphql`
  query WorkPageQuery {
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
    illustrations: allSanityIllustration {
      edges {
        node {
          images {
            ...SanityImage
            width
            alt
            caption
          }
        }
      }
    }
  }
`;

const WorkPage = (props) => {
  const { data, errors } = props;

  const filter = useQueryParam("filter", null);

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const postNodes = data && data.posts && mapEdgesToNodes(data.posts);
  const illustrations =
    data && data.illustrations && mapEdgesToNodes(data.illustrations);
  const images =
    illustrations &&
    illustrations.reduce((a, b) => (b.images.length && a.push(b), a), [])[0]
      .images;

  return (
    <Layout>
      <SEO title="Work" />
      <Container>
        <h1>Work</h1>
        <WorkFilters
          projectCount={postNodes.length}
          illustrationCount={(images && images.length) || 0}
          filter={filter}
        />
        {filter && filter === "illustrations" && images && images.length && (
          <IllustrationGrid illustrations={images} />
        )}
        {!filter && postNodes && postNodes.length && (
          <ProjectPreviewGrid nodes={postNodes} />
        )}
      </Container>
    </Layout>
  );
};

export default WorkPage;
