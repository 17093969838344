import React from "react";
// import { Link } from "gatsby";
import { cn } from "../lib/helpers";

import * as styles from "./work-filters.module.css";

const WorkFilters = ({ projectCount, illustrationCount, filter }) => (
  <div className={styles.workFilter}>
    <a href="/work" className={cn(!filter && styles.active, styles.filterLink)}>
      Projects ({projectCount})
    </a>
    <a
      href="/work?filter=illustrations"
      className={cn(
        filter && filter == "illustrations" && styles.active,
        styles.filterLink
      )}
    >
      Graphic/Illustrations ({illustrationCount})
    </a>
  </div>
);

export default WorkFilters;
