// import { Link } from "gatsby";
import React from "react";
import ProjectPreview from "./project-preview";

// import { Masonry } from "masonic";

import * as styles from "./project-preview-grid.module.css";

// const MasonryCard = ({ data }) => <ProjectPreview {...data} />;

function ProjectPreviewGrid(props) {
  return (
    <div className={styles.root}>
      {props.title && <h2 className={styles.headline}>{props.title}</h2>}

      <div className={styles.grid}>
        {props.nodes.map((node, index) => (
          <ProjectPreview {...node} key={index} />
        ))}
      </div>

      {props.browseMoreHref && (
        <div className={styles.browseMoreNav}>
          <a href={props.browseMoreHref}>Browse more</a>
        </div>
      )}
    </div>
  );
}

ProjectPreviewGrid.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: "",
};

export default ProjectPreviewGrid;
