// import { Link } from "gatsby";
import React from "react";
// import Illustration from "./project-preview";

import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

// const width = node.width || 1280;

// imageUrlFor(node.asset.url).width(width).url()}

import { Masonry } from "masonic";

import * as styles from "./project-preview-grid.module.css";

function IllustrationGrid(props) {
  return (
    <div className={styles.root}>
      {props.title && <h2 className={styles.headline}>{props.title}</h2>}

      <Masonry
        items={props.illustrations}
        render={(props) => {
          return (
            <img
              src={imageUrlFor(buildImageObj(props.data))
                .width(1800)
                // .height(Math.floor((3 / 4) * 1280))
                // .fit("crop")
                .url()}
              alt={props.data.alt}
            />
          );
        }}
        columnGutter={25}
        // columnWidth={300}
        columnCount={2}
      />

      {/* {props.browseMoreHref && (
        <div className={styles.browseMoreNav}>
          <Link to={props.browseMoreHref}>Browse more</Link>
        </div>
      )} */}
    </div>
  );
}

IllustrationGrid.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: "",
};

export default IllustrationGrid;
